@use 'common'
@import 'hiding-header/dist/style.css'

:root
	// fonts are defined in _app.tsx
	// --font-plus-jakarta-sans
	// --font-body
	// --font-heading

	--page-horizontal-spacing: 1.5rem
	--content-width-normal: #{common.$contentWidth-normal}
	--content-width-medium: #{common.$contentWidth-medium}
	--content-width-wide: #{common.$contentWidth-wide}

	--content-gap-medium: 3.75rem
	--content-gap-default: 9.375rem
	--content-gap-small: 1.5625rem
	--header-estimated-height: #{common.$header-estimatedHeight}

	--ProgressLoadingIndicator-color: common.$greyBasic

	@media (min-width: common.$break35)
		--page-horizontal-spacing: 1.5rem

	@media (min-width: common.$break48)
		--content-gap-default: 18.75rem
		--content-gap-small: 3.75rem

body
	+common.text
	margin: unset
	padding: unset
	font-family: var(--font-body)
	background-color: common.$greyBlack
	color: common.$white

*, *::before, *::after
	box-sizing: border-box

button
	margin: unset
	font-size: inherit
	cursor: pointer
	font-family: inherit
	background-color: transparent
	border: unset

a
	color: inherit

	&:hover, &:focus-visible
		text-decoration: none

h1
	+common.heading1

h2
	+common.heading2
