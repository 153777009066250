.hidingHeader{position:relative;--hidingHeader-height: auto;--hidingHeader-bounds-height: auto;--hidingHeader-animation-offset: 0px;z-index:10;height:var(--hidingHeader-bounds-height);margin-bottom:calc(var(--hidingHeader-height) - var(--hidingHeader-bounds-height));pointer-events:none}.hidingHeader-in{position:relative;position:-webkit-sticky;position:sticky;top:0;pointer-events:auto;transition:transform .2s;transform:translateY(var(--hidingHeader-animation-offset))}@media(prefers-reduced-motion){.hidingHeader-in{transition-duration:1ms;transition-timing-function:step-end}}
:root{--page-horizontal-spacing: 1.5rem;--content-width-normal: 57.5rem;--content-width-medium: 78.125rem;--content-width-wide: 90rem;--content-gap-medium: 3.75rem;--content-gap-default: 9.375rem;--content-gap-small: 1.5625rem;--header-estimated-height: 5.5rem;--ProgressLoadingIndicator-color: common.$greyBasic}@media(min-width: 35rem){:root{--page-horizontal-spacing: 1.5rem}}@media(min-width: 48rem){:root{--content-gap-default: 18.75rem;--content-gap-small: 3.75rem}}body{font-size:.875rem;line-height:1.27;font-weight:400;margin-top:unset;margin:unset;padding:unset;font-family:var(--font-body);background-color:#060606;color:#fff}@media(min-width: 30rem){body{font-size:1.25rem}}*,*::before,*::after{box-sizing:border-box}button{margin:unset;font-size:inherit;cursor:pointer;font-family:inherit;background-color:rgba(0,0,0,0);border:unset}a{color:inherit}a:hover,a:focus-visible{text-decoration:none}h1{font-family:var(--font-heading);font-weight:500;margin-top:unset;line-height:1.1081;font-size:2.1875rem;margin-bottom:1rem}@media not all and (min-width: 48rem){h1 br{display:none}}@media(min-width: 22rem){h1{font-size:2.875rem}}@media(min-width: 48rem){h1{font-size:4.625rem}}@media(min-width: 62rem){h1{font-size:6.25rem;margin-bottom:2rem}}h2{font-family:var(--font-heading);font-weight:500;margin-top:unset;font-size:1.5rem;line-height:1.25;margin-bottom:.5rem}@media not all and (min-width: 48rem){h2 br{display:none}}@media(min-width: 35rem){h2{font-size:1.875rem}}@media(min-width: 48rem){h2{font-size:2.5rem;margin-bottom:1rem}}
/* cyrillic-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_1a49b6';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(/_next/static/media/eed6db14ac3b93a0.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* vietnamese */
@font-face {
  font-family: '__Plus_Jakarta_Sans_1a49b6';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(/_next/static/media/65053818c3abcf97.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_1a49b6';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(/_next/static/media/034043092db1e233.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Plus_Jakarta_Sans_1a49b6';
  font-style: normal;
  font-weight: 200 800;
  font-display: swap;
  src: url(/_next/static/media/2b3f1035ed87a788.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Plus_Jakarta_Sans_Fallback_1a49b6';src: local("Arial");ascent-override: 98.16%;descent-override: 20.99%;line-gap-override: 0.00%;size-adjust: 105.75%
}.__className_1a49b6 {font-family: '__Plus_Jakarta_Sans_1a49b6', '__Plus_Jakarta_Sans_Fallback_1a49b6';font-style: normal
}

